/*
* Arttu Lehtola 
* old-node-landing page
*/

import * as React from 'react';
import CardFactory from './CardFactory';
import data from './Data/data';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, IconButton, } from '@mui/material';
import { scroller } from 'react-scroll';
import './Builder.css';
import Header from './Header';

const handleClick = (id) => {
    if (id === 'infoon') {
        scroller.scrollTo('info-kortti', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
        });
    }

}

// Builds majority of landing page with help of cards
// Some of the cards utilize the data given in their parameters
function Builder() {
    return (
        <Box
            id="Kokeilu"
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                flexDirection: 'column'
            }}
        >
            <Box
                id="uloinLaatikko"
                sx={{
                    display: 'flex',
                    
                    justifyContent: 'center',
                    alignContent: 'center',
                    flexWrap: 'wrap',
                    flexDirection: 'column'

                }}
            >
                <Header />
                <Box
                    sx={{
                        margin: 0,
                        textAlign: 'center',
                        width: '100%',
                        height: '100%',
                        paddingTop: '20vh',
                    }}
                >
                    <Box
                        id="koti"
                    >
                        <div
                            id="otsikko"
                            style={{
                                marginBottom: 0,
                            }}
                        >
                            Header
                        </div>
                        <div
                            id="alaOtsikko"
                            style={{
                                marginBottom: '5vh',
                            }}
                        >
                            - X.X.XXXX -
                        </div>
                    </Box>
                    <IconButton
                        onClick={() => handleClick("infoon")}
                    >
                        <KeyboardArrowDownIcon
                            id="infoon"
                            style={{
                                color: '#fff',
                                fontSize: '5rem',
                            }}
                        />
                    </IconButton>
                </Box>
                <div
                    id="info-kortti"
                    className="kuvakortti"
                    style={{
                        marginTop: '50vh',
                        marginBottom: '15vh',
                        width: '100vw',
                        display: 'flex',
                        justifyContent: 'center'

                    }}
                >
                    <CardFactory
                        content={data[0]}
                        type={"info"}
                        direction={"normal"}
                        style={{
                            margin: 0,
                        }}
                    />
                </div>
                <div
                    id="ilmo-kortti"
                    className="kuvakortti"
                    style={{
                        marginBottom: '15vh',
                        width: '100vw',
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >

                    <CardFactory
                        content={data[1]}
                        type={"imagecard"}
                        direction={"normal"}
                        style={{
                            margin: 0,
                        }}

                    />
                </div>
                <div
                    id="kpp-kortti"
                    className="kuvakortti"
                    style={{
                        marginBottom: '15vh',
                        width: '100vw',
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <CardFactory
                        content={data[2]}
                        type={"imagecard"}
                        direction={"normal"}
                        style={{
                            margin: 0,
                        }}
                    />
                </div>
                <div
                    id="majo-kortti"
                    className="kuvakortti"
                    style={{
                        marginBottom: '15vh',
                        width: '100vw',
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <CardFactory
                        content={data[3]}
                        type={"imagecard"}
                        direction={"reverse"}
                        style={{
                            margin: 0,
                        }}
                    />
                </div>
                <div
                    id="valo-kortti"
                    className="kuvakortti"
                    style={{
                        paddingBottom: '15vh',
                        width: '100vw',
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <CardFactory
                        content={data[4]}
                        type={"imagecard"}
                        direction={"normal"}
                        style={{
                            margin: 0,
                        }}
                    />
                </div>

                <div
                    id="tied-kortti"
                    className="kuvakortti"
                    style={{
                        paddingBottom: '15vh',
                        width: '100vw',
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <CardFactory
                        content={data[5]}
                        type={"imagecard"}
                        direction={"normal"}
                        style={{
                            margin: 0,
                        }}
                    />
                </div>
                <div
                    id="yhteys-kortti"
                    style={{
                        display: 'flex',
                        alignContent: 'center',
                        justifyContent: 'center',
                        paddingBottom: '15vh',
                        marginTop: '10vh',
                    }}
                >
                    <CardFactory
                        para={null}
                        type={"contact"}
                        direction={"normal"}
                        style={{
                            margin: 0,
                        }}
                    />
                </div>
            </Box>
        </Box>
    )
}
export default Builder;