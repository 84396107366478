/*
* Arttu Lehtola 
* old-node-landing page
*/

import * as React from 'react';
import { Button, Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { useNavigate, Link, Navigate as Redirect, withRouter } from 'react-router-dom';
export default function CardFactory({ content, type, direction }) {
    let navigate = useNavigate();
    const onPress = (event) => {
        navigate("/form");
    }

    if (type === "info") {
        return (
            <Card
                sx={{
                    display: 'flex',
                    //minWidth: '75vw',
                    alignContent: 'center',
                    justifyContent: 'center',
                    flexDirection:
                    {
                        xs: 'column',
                        sm: 'column',
                        md: 'row-reverse',
                        lg: 'row-reverse'
                    },
                    width: {
                        xs: '75%',
                        sm: '75%',
                        md: '50%',
                        lg: '50%',
                    },
                    height: '100%',
                }}
            >
                <CardMedia
                    sx={{
                        width: { xs: '99%', sm: '99%', md: '50%', lg: '50%' },
                        minHeight: '99%',
                        margin: '0.5%',
                        borderRadius: '3px',
                    }}
                    component="img"
                    image={content.pic}
                    title={content.pde}
                />
                <CardContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: { xs: '100%', sm: '100%', md: '50%', lg: '50%' },
                        padding: 0,
                        margin: 0,
                        p: 0,
                        '&:last-child': { pb: 0 },
                    }}
                >
                    <Typography
                        gutterBottom variant="h5"
                        component="h2"
                        id="header"
                        sx={{
                            paddingLeft: 2,
                            paddingRight: 2,
                            paddingTop: 2,
                            fontSize:
                            {
                                xs: '6.8vw',
                                sm: '4.5vw',
                                md: "2.8vw",
                                lg: "2.95vw"
                            },
                        }}
                    >
                        {content.hea}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        sx={{
                            paddingLeft: 2,
                            paddingRight: 2,
                            margin: 0,
                            marginBottom: '0.75rem',
                            fontSize:
                            {
                                xs: '5.0vw',
                                sm: '2.8vw',
                                md: "1.8vw",
                                lg: "1.95vw",
                                xl: "1.5vw"

                            },

                        }}
                    >
                        {content.tex1}
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'stretch',
                            margin: '3vw 1vw 1vw 1vw',
                        }}
                    >
                        <Button
                            sx={{
                                width: '100%',
                                backgroundColor: '#496ae3'
                            }}
                            variant="contained"
                            color="secondary"
                            onClick={onPress}
                        >
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                                sx={{
                                    color: 'white',
                                    margin: 0,
                                    padding: 0,
                                    fontSize:
                                    {
                                        xs: '5.0vw',
                                        sm: '2.5vw',
                                        md: "1.8vw",
                                        lg: "1.95vw",
                                        xl: "1.5vw"

                                    },
                                }}
                            >
                                PARTAKE
                            </Typography>
                        </Button>
                    </Box>
                </CardContent>
            </Card>

        );
    }
    else if (type === "imagecard" && direction === "normal") {
        return (
            <Card
                sx={{
                    display: 'flex',
                    //minWidth: '75vw',
                    flexDirection:
                    {
                        xs: 'column',
                        sm: 'column',
                        md: 'row-reverse',
                        lg: 'row-reverse'
                    },
                    width: {
                        xs: '75%',
                        sm: '75%',
                        md: '50%',
                        lg: '50%',
                    },
                    height: '100%',
                }}
            >
                <CardMedia
                    sx={{
                        width: { xs: '99%', sm: '99%', md: '50%', lg: '50%' },
                        minHeight: '99%',
                        margin: '0.5%',
                        borderRadius: '3px',
                        position: 'relative',
                    }}
                    component="img"
                    image={content.pic}
                    title={content.pde}
                />
                <CardContent
                    sx={{

                        display: 'flex',
                        flexDirection: 'column',
                        width: { xs: '100%', sm: '100%', md: '50%', lg: '50%' },
                        padding: 0,
                        margin: 0,
                        p: 0,
                        '&:last-child': { pb: 0 },
                    }}
                >
                    <Typography
                        gutterBottom variant="h5"
                        component="h2"
                        sx={{
                            paddingLeft: 2,
                            paddingRight: 2,
                            paddingTop: 2,
                            fontSize:
                            {
                                xs: '6.8vw',
                                sm: '4.5vw',
                                md: "2.8vw",
                                lg: "2.95vw"
                            },
                        }}
                    >
                        {content.hea}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        sx={{
                            paddingLeft: 2,
                            paddingRight: 2,
                            margin: 0,
                            marginBottom: '0.75rem',
                            fontSize:
                            {
                                xs: '5.0vw',
                                sm: '2.8vw',
                                md: "1.8vw",
                                lg: "1.95vw",
                                xl: "1.5vw"
                            },

                        }}
                    >
                        {content.tex1}
                    </Typography>
                </CardContent>
            </Card>
        );
    }
    else if (type === "imagecard" && direction === "reverse") {
        return (
            <Card
                sx={{
                    display: 'flex',
                    //minWidth: '75vw',
                    flexDirection:
                    {
                        xs: 'column',
                        sm: 'column',
                        md: 'row',
                        lg: 'row'
                    },
                    width: {
                        xs: '75%',
                        sm: '75%',
                        md: '50%',
                        lg: '50%',
                    },
                    height: '100%',
                }}
            >
                <CardMedia
                    sx={{
                        width: { xs: '99%', sm: '99%', md: '50%', lg: '50%' },
                        minHeight: '99%',
                        margin: '0.5%',
                        borderRadius: '3px',
                        position: 'relative',
                    }}
                    component="img"
                    image={content.pic}
                    title={content.pde}
                />
                <CardContent
                    sx={{

                        display: 'flex',
                        flexDirection: 'column',
                        width: { xs: '100%', sm: '100%', md: '50%', lg: '50%' },
                        padding: 0,
                        margin: 0,
                        p: 0,
                        '&:last-child': { pb: 0 },
                    }}
                >
                    <Typography
                        gutterBottom variant="h5"
                        component="h2"
                        sx={{
                            paddingLeft: 2,
                            paddingRight: 2,
                            paddingTop: 2,
                            fontSize:
                            {
                                xs: '6.8vw',
                                sm: '4.5vw',
                                md: "2.8vw",
                                lg: "2.95vw"
                            },
                        }}
                    >
                        {content.hea}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        sx={{
                            paddingLeft: 2,
                            paddingRight: 2,
                            margin: 0,
                            marginBottom: '0.75rem',
                            fontSize:
                            {
                                xs: '5.0vw',
                                sm: '2.8vw',
                                md: "1.8vw",
                                lg: "1.95vw",
                                xl: "1.5vw"
                            },

                        }}
                    >
                        {content.tex1}
                    </Typography>
                </CardContent>
            </Card>





        );
    }
    else if (type === "contact") {
        return (

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    maxWidth: {
                        xs: '80vw',
                        sm: '80vw',
                        md: '50vw',
                        lg: '50vw',
                    },
                }}
            >
                <Card
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        backgroundColor: 'white',
                    }}
                >
                    <CardContent
                        sx={{
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            borderRadius: '3px',
                            borderColor: '#e8e4e4',
                            margin: '.25em',
                            p: 0,
                            '&:last-child': { pb: 0 },
                        }}
                    >
                        <Typography
                            gutterBottom variant="h5"
                            component="h2"
                            sx={{
                                paddingLeft: 2,
                                paddingRight: 2,
                                paddingTop: 2,
                                fontSize:
                                {
                                    xs: '6.8vw',
                                    sm: '4.5vw',
                                    md: "2.8vw",
                                    lg: "2.95vw",
                                    xl: "1.5vw"
                                },
                            }}
                        >
                            Contact Info
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                            sx={{
                                paddingLeft: 2,
                                paddingRight: 2,
                                margin: 0,
                                marginBottom: '0.75rem',
                                fontSize:
                                {
                                    xs: '5.0vw',
                                    sm: '2.8vw',
                                    md: "1.8vw",
                                    lg: "1.95vw",
                                    xl: "1.5vw"
                                },

                            }}
                        >
                            <b>XXX</b><br /> ##########<br />
                        </Typography>
                    </CardContent>
                </Card>
            </Box>
        );
    }
    else {

    }

}
