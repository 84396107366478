/*
* Arttu Lehtola 
* old-node-landing page
*/

import * as React from 'react';
import Builder from './module/Builder';
import { Box } from '@mui/material';
import './Homepage.css';

function Homepage() {
  return (
    // Import & utilize Builder
    <Box 
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        width: '100vw'
      }}
    >
      <Builder />
    </Box>
  );
}

export default Homepage;
